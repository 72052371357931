<template>
  <div class="ActivityFile">
    <div class="keenMindDetail">
      <div v-loading="loading"></div>
      <div class="crumb" v-if="clientWidth > 750">
        <breadcrumb :refer="refer"></breadcrumb>
      </div>
      <div v-if="Isdata" class="keenMindDetail-banner" style="margin-top: 0">
        <img v-if="keenDetail.banner" :src="keenDetail.banner" alt="" />
        <img v-else src="@/assets/PC/keenMind/keendetailbanner.png" alt="" />
        <h1 v-if="!keenDetail.banner" class="hidden">{{ keenDetail.title }}</h1>
        <p v-if="!keenDetail.banner" class="flex1">
          <van-icon name="clock" class="mr co-voi" />
          {{ keenDetail.startTimeStr }} - {{ keenDetail.endTimeStr }}
        </p>
      </div>
      <div v-if="Isdata" class="ActivitySignUp-content">
        <div class="upload-label"> <i style="color: #ff0000; font-weight: bold"> * </i>  上传文档（单文档时 word 格式，多文档时 ZIP 包格式且不超过1G）：</div>
        <div class="upload-word flex">
          <div class="item-files mr15">
            <a :href="WorksClassify.word" class="hidden link block">{{ WorksClassify.wordName }}</a>
          </div>
          <el-upload
            :action="baseUrl+'/Files/UploadAzureBlob'"
            :before-upload="(file) => beforeFile(1, file)"
            :on-success="(response, file, fileList) => successFile(1, response, file, fileList)"
            >
            <el-button size="small" type="info" :loading="loadings[0]"><i class="el-icon-upload2"></i> 上传</el-button>
          </el-upload>
        </div>
        <div class="upload-label">上传视频（单视频时 MP4 格式，多视频 ZIP 包格式且不超过1G）：</div>
        <div class="upload-video flex">
          <div class="item-files mr15">
            <a :href="WorksClassify.video" class="hidden link block">{{ WorksClassify.videoName }}</a>
          </div>
          <el-upload
            :action="baseUrl+'/Files/UploadAzureBlob'"
            :before-upload="(file) => beforeFile(2, file)"
            :on-success="(response) => successFile(2, response)"
            >
            <el-button size="small" type="info" :loading="loadings[1]"><i class="el-icon-upload2"></i> 上传</el-button>
          </el-upload>
        </div>
        <div class="upload-label">上传图片（单图片时 JPG 格式，多图片时 ZIP 包格式且不超过1G）：</div>
        <div class="upload-image flex">
          <div class="item-files mr15">
            <a :href="WorksClassify.image" class="hidden link block">{{ WorksClassify.imageName }}</a>
          </div>
          <el-upload
            :action="baseUrl+'/Files/UploadAzureBlob'"
            :before-upload="(file) => beforeFile(3, file)"
            :on-success="(response, file, fileList) => successFile(3, response, file, fileList)"
            >
            <el-button size="small" type="info" :loading="loadings[2]"><i class="el-icon-upload2"></i> 上传</el-button>
          </el-upload>
        </div>
        <div class="ActivityFile-operation tc">
          <el-button type="info" @click="subFiles" :loading="loadingBtn">提 交</el-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import breadcrumb from "@/components/Breadcrumb/index.vue";
import { SubmitWorksClassify, GetActivityDetail } from "@/api/KeenMind.js";
import { common_msg_update } from "@/utils/common.js";
import { dataState } from "@/api/all.js";
import { ApiToken } from '@/utils/ApiToken'

export default {
  name: "ActivityFile",
  data() {
    return {
      loading: true,
      loadings: [false, false, false],
      loadingBtn: false,
      refer: [
        { name: "首页", href: "/" },
        { name: "剑鸣", href: "/Home/keenMind" },
      ],
      baseUrl: ApiToken.baseApi ,
      keenDetail: {}, // !详情页数据
      Isdata: false,
      WorksClassify: {
        activityId: "",
        title: "",
        word: "",
        wordName: "",
        video: "",
        videoName: "",
        image: "",
        imageName: ""
      }
    };
  },
  mounted() {
    if(!this.$store.state.token) {
      this.$router.push('/Home/keenMindDetail?id=' + this.$route.query.id);
    }
    if (!this.$route.query.id) {
      this.$toast("页面异常，正在返回首页");
      setTimeout(() => {
        this.$router.push("/Home/keenMind");
      }, 500);
      return false;
    }
    this.Init();

    // 数据统计
    const data = {
      visitType: 1,
      gid: sessionStorage.getItem("gid") ? sessionStorage.getItem("gid") : "",
      url: window.location.href,
      pageUrl: "ActivityFile",
      relationId: this.$route.query.id,
    };
    dataState(data).then((res) => {
      sessionStorage.setItem("gid", res.data);
    });
  },
  methods: {
    // !获取详情数据
    Init() {
      const Id = { id: this.$route.query.id };
      GetActivityDetail(Id).then((res) => {
        if (res.status == 1) {
          if(res.data.isSignUp == 0) {
            this.$router.push("/Home/keenMindDetail?id=" + this.$route.query.id);
          }

          if(res.data.type == 1) {
            this.$toast('线下活动不支持提交作品！');
            this.$router.push("/Home/keenMindDetail?id=" + this.$route.query.id);
          }

          if(res.data.isSubmit == 1) {
            this.$toast('您已提交过文件！');
            this.$router.push("/Home/keenMindDetail?id=" + this.$route.query.id);
          }
          this.keenDetail = res.data;
          if (this.refer.length < 3) {
            this.refer.push({
              name: res.data.title,
              href: "/Home/keenMindDetail?id=" + this.$route.query.id,
            });
            this.refer.push({ name: "提交作品" });
          }
          this.Isdata = true;
        } else {
          this.$toast(res.message);
          this.$router.push("/Home/keenMind");
        }
        this.loading = false;
      });
    },
    // !文件上传前的回调
    beforeFile (type, file) {
      if (file.size > 1 * 1024 * 1024 * 1024) {
        this.$toast("文件大小超出所能上传的最大限制（1GB）")
        return false;
      }
      const filetype = '.' + file.name.split('.')[file.name.split('.').length - 1 ];
      switch(type) {
        case 1:
          if(filetype === '.doc' || filetype === '.docx' || filetype === '.zip') {
            this.loadings.splice(0, 1, true);
          } else {
            this.$toast("上传文件格式与要求不符，请重新上传！");
            return false;
          }
        break;
        case 2:
          if(filetype === '.mp4' || filetype === '.zip') {
            this.loadings.splice(1, 1, true);
          } else {
            this.$toast("上传文件格式与要求不符，请重新上传！");
            return false;
          }
        break;
        case 3:
          if(filetype === '.jpg' || filetype === '.zip') {
            this.loadings.splice(2, 1, true);
          } else {
            this.$toast("上传文件格式与要求不符，请重新上传！");
            return false;
          }
        break;
      }
    },
    // !文件上传成功回调
    successFile(type, response) {
      if(response.status == 1) {
        this.$toast("上传成功！");
        switch(type) {
          case 1:
            this.WorksClassify.word = response.data.fileUrl;
            this.WorksClassify.wordName = response.data.fileName;
            this.loadings.splice(0, 1, false);
          break;
          case 2:
            this.WorksClassify.video = response.data.fileUrl;
            this.WorksClassify.videoName = response.data.fileName;
            this.loadings.splice(1, 1, false);
          break;
          case 3:
            this.WorksClassify.image = response.data.fileUrl;
            this.WorksClassify.imageName = response.data.fileName;
            this.loadings.splice(2, 1, false);
          break;
        }
      } else {
        this.loadings.splice(type - 1 , 1, false);
        this.$toast(response.message);
      }
    },
    // !提交作品
    subFiles() {
      if(!this.WorksClassify.word) {
        this.$toast('请先上传wold文档再提交吧！');
        return false;
      }

      this.WorksClassify.activityId = this.$route.query.id;
      this.WorksClassify.title = this.keenDetail.title;
      this.loadingBtn = true;
      SubmitWorksClassify(this.WorksClassify).then(res => {
        if(res.status == 1) {
          this.$toast('提交成功！');
          common_msg_update();
          setTimeout(() => {
            this.$router.push('/Home/keenMindDetail?id=' + this.$route.query.id);
          }, 500);
        } else {
          this.loadingBtn = false;
          this.$toast(res.message);
        }
      })

    }
  },
  computed: {
    clientWidth() {
      return this.$store.state.clientWidth;
    },
    Infos() {
      return this.$store.state.Infos;
    },
  },
  components: {
    breadcrumb,
  },
};
</script>

<style lang="scss">
$mobil_base: 750;

@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}
.ActivityFile {
  .el-upload-list.el-upload-list--text { display: none; }
  .item-files {width: 100%; height: 35px; line-height: 34px; background: #ccc; padding-left: 10px; overflow: hidden;}
  .upload-label {line-height: 1.5; margin: 10px 0;}
  .ActivityFile-operation {margin-top: 30px;}
}

</style>